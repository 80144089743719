#theme-header {
    font-weight: 600;
    font-size: x-large;
    color: #fcf5da;
}
#book-header {
    font-weight: 600;
    font-size: x-large;
    color: black;
}
#theme-book {
    background-color: #fcf5da;
}
#theme-boxes{
    /* background-color: #3788d8;

     */
}
#theme-boxes > div {
    font-size: large;
    margin: 0.5em 0.0em 0.5em 0.5em;
}
#theme-box:hover{
    background-color: #aea58a;
}
#theme-box.active {
    background-color: #fcf5da;
}
.book:hover{
    background-color: rgba(0, 0, 0, 0.04);
}
#BookImg{
    padding: 15px 32px;
}
#BookName{
    padding: 15px 32px;
    text-align: center;
}
#ViewNow{
    background-color:rgb(249,232,163);
    color: black;
    padding: 15px 32px;
    text-align: center;

    font-size: 16px;

    cursor: pointer;

}

#book_container {
    border: 1px solid green;
    padding: 5%;
    text-align: center;
}

@media (max-width: 420px) {
    /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */
    #book_img {
        max-height: 200px;
    }
}

