#first_row > .col{
    width: 30%;
    background-color: red;
    margin-left: 0.5em;
    margin-right: 0.5em;
}
#first_row > .col.active{
    background-color: #b3f3f1;
    color: #ff1616;
}
[name="payment_type"]{
    height: 1.4em;
    width: 1.4em;
}
