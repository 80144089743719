#selected{
    color: yellowgreen;
}

#desk-dx {
    height: 100vh;
    margin: 0;
    padding: 0;
}

.tail {
    height: 50vh;
    border: dotted red 1px;
    overflow: no-content;
    position: relative;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
}
.tail img {
    height: 100%;
    width: auto;
    position: absolute;
    padding: 0;
    margin: 0 auto;
}

#cascade{
    flex-direction: row;
}
#clothes, #headpiece{
    display: none;
}
